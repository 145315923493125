import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import { parseSearch } from '../../helpers/blog';
import { RouteFormatter } from '../../routes';
import bp1 from '../../assets/img/bp-1.jpg?sizes[]=512';
import bp2 from '../../assets/img/bp-2.jpg?sizes[]=512';
import bp3 from '../../assets/img/bp-3.jpg?sizes[]=512';
import bp4 from '../../assets/img/bp-4.jpg?sizes[]=512';
import bp5 from '../../assets/img/bp-5.jpg?sizes[]=512';
import bp6 from '../../assets/img/bp-6.jpg?sizes[]=512';
import bp7 from '../../assets/img/bp-7.png?sizes[]=512';
import bp8 from '../../assets/img/bp-8.png?sizes[]=512';
import bp9 from '../../assets/img/bp-9.png?sizes[]=512';
import defSpotImg from '../../assets/img/blog/blog-placeholder.png?sizes[]=450,sizes[]=600';
import topTrainersImg from '../../assets/img/blog/top-trainers.jpg';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import { ImageHelper } from '../../classes/image-helper.class';
import useMobileScreen from '../../services/hooks/useMobileScreen';
import BlogArticleDesktopLow from '../../components/blog-article-desktop-low';
import BlogArticleDesktopHigh from '../../components/blog-article-desktop-high';
import BlogArticleSimpleDesktop from '../../components/blog-article-simple-desktop';
import BlogArticleSimple from '../../components/blog-article-simple';
import SniffPagination from '../../components/sniff-pagination';
import SpotBreadcrumbs from '../../components/spot-breadcrumbs';
import BlogCategories from '../../components/blog-categories';
import BlogArticle from '../../components/blog-article';
import BlogFooter from '../../components/blog-footer';
import BlogTopNav from '../../components/blog-top-nav';
import PictureSet from '../../components/picture-set';
import FooterNew from '../../components/footer-new';
import Preloader from '../../components/preloader';
import SvgFeaturedRibbon from 'src/assets/svg/SvgFeaturedRibbon.svg';
import SvgKeyboardRight from 'src/assets/svg/SvgKeyboardRight.svg';
import './index.scss';

const useGetBlogCategories = require('../../services/hooks/useGetBlogCategories');
const useGetBlogPaginationPosts = require('../../services/hooks/useGetBlogPaginationPosts');

const b = bemCn('sniff-blog');

const BLOG_LIMIT = 22;
const listBread = [{ name: 'Blog' }];

const blogs_content = [
    {
        title: 'A Comprehensive Guide to Dog Exercise',
        img: ImageHelper.getUrlFromOptions(bp1, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/dog-enrichment/a-comprehensive-guide-to-dog-exercise',
    },
    {
        title: 'How To Crate train An Older Dog - A Comprehensive Guide',
        img: ImageHelper.getUrlFromOptions(bp2, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/dog-training/crate-train-an-older-dog',
    },
    {
        title: 'How To Socialize A Dog - A Comprehensive Guide',
        img: ImageHelper.getUrlFromOptions(bp3, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/dog-training/how-to-socialize-a-dog',
    },
    {
        title: 'Leash Training: A Guide to Training Your Dog to Walk on a Leash',
        img: ImageHelper.getUrlFromOptions(bp4, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/dog-training/leash-training-a-guide-to-training-your-dog-to-walk-on-a-leash',
    },
    {
        title: 'Skijoring: A Guide to Skijoring with Dogs',
        img: ImageHelper.getUrlFromOptions(bp5, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/dog-enrichment/skijoring-a-guide-to-skijoring-with-dogs',
    },
    {
        title: 'The Best Dog Enrichment Toys',
        img: ImageHelper.getUrlFromOptions(bp6, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/dog-enrichment/the-best-dog-enrichment-toys',
    },
    {
        title: 'The Best United States Dog Parks',
        img: ImageHelper.getUrlFromOptions(bp7, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/city-dog-parks/the-best-united-states-dog-parks',
    },
    {
        title: 'The Best Indoor Dog Parks in the United States',
        img: ImageHelper.getUrlFromOptions(bp8, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/city-dog-parks/the-best-indoor-dog-parks-in-the-united-states',
    },
    {
        title: 'The Best Off-Leash Dog Parks in the United States',
        img: ImageHelper.getUrlFromOptions(bp9, `full-fit-in/${IMG_RESIZES.x675}`),
        url: 'https://www.sniffspot.com/blog/city-dog-parks/the-best-off-leash-dog-parks-in-the-united-states',
    },
];

const Blog = ({ search, pathname = '/blog', blogPostsSSR, blogCategoriesSSR, totalPages, isMobileSSR, useStateSSR }) => {
    const [myWin, setMyWin] = useState({});
    const isClient = typeof window !== 'undefined';
    const isMobile = useStateSSR ? isMobileSSR : useMobileScreen();
    const qs = search ? parseSearch(search) : isClient ? parseSearch(myWin.location?.search) : '';
    const blogPage = qs?.page ? parseInt(qs?.page) : 1;
    const queryVariables = { page: blogPage, limit: BLOG_LIMIT };
    const { data: clientCat, loading: loadingCat } = useStateSSR ? {} : useGetBlogCategories.default();
    const { data: clientPosts, loading: loadingPosts } = useStateSSR ? {} : useGetBlogPaginationPosts.default(queryVariables);
    const initialPage = blogPage - 1;
    const mainPage = initialPage < 1;
    const mbArt = mainPage ? [1, 6] : [0, 5];
    const mbArtSimple = mainPage ? 6 : 5;
    const pcArt = mainPage ? [1, 5] : [0, 4];
    const pcArtSimple = mainPage ? 5 : 4;
    const blogPosts = blogPostsSSR || clientPosts?.blogPostsPaged?.collection;
    const blogCategories = blogCategoriesSSR || clientCat || [];
    const pageCount = totalPages || clientPosts?.blogPostsPaged?.metadata?.totalPages;
    const firstPost = blogPosts?.length > 0 && blogPosts[0];
    const thumbnail = firstPost?.thumbnail ? ImageHelper.getUrlFromOptions(firstPost.thumbnail, `fit-in/${IMG_RESIZES.x0450}`) : defSpotImg;

    const myPathname = pathname || isClient ? myWin.location?.pathname : '';

    const hrefBuilder = (pageIndex) => `${myPathname}?page=${pageIndex}`;

    const onPageChange = ({ selected }) => {
        const newPage = selected + 1;
        window.location = `${myPathname}?page=${newPage}`;
    };

    useEffect(() => {
        setMyWin(window);
    }, []);

    if (loadingCat || loadingPosts) {
        return <Preloader fullWindow />;
    }

    return (
        <div className={b()}>
            <BlogTopNav />
            <div className={b('header')}>
                <div className="desktop-container">
                    <SpotBreadcrumbs {...{ listBread, noPaddingTop: true, SSR: !!useStateSSR }} />
                    <h1 className={b('header_title').mix('snif-m1 snif-xl-pc')}>Guides and Resources for Dog Owners</h1>
                    <p className="snif-p text-center text-md-start">
                        Our blog is dedicated to relevant, high quality articles with modern advice aligned with recognized dog experts.
                    </p>
                </div>
            </div>
            {mainPage && (
                <div className="desktop-container">
                    <a href={firstPost?.slug} className={b('featured')}>
                        <SvgFeaturedRibbon className={b('featured_ribbon')} />
                        <PictureSet
                            imgs={thumbnail}
                            className={b('featured_thumbnail')}
                            width={600}
                            height={isMobile ? 220 : 396}
                            useState={useStateSSR}
                            SSR={!!useStateSSR}
                            alt={firstPost?.title + ' thumbnail'}
                            method="eager"
                        />
                        <div className={b('featured_content')}>
                            <h4 className="snif-p snif-l2-pc snif-semibold text-dark mb-1 mb-md-3 pb-05 pb-md-0">{firstPost?.title}</h4>
                            <div className={b('desc').mix('featured')} dangerouslySetInnerHTML={{ __html: firstPost?.descriptionShort }} />
                            <p className={b('read-more')}>
                                Read more
                                <SvgKeyboardRight />
                            </p>
                        </div>
                    </a>
                </div>
            )}
            <div className={b('content').mix(mainPage ? '' : 'sub-page')}>
                <div className="desktop-container">
                    <div className="mx-2 mx-md-0">
                        {mainPage && (
                            <a href={RouteFormatter.blogAll()} className="sniff-blog__read-more pt-0 d-none d-md-block">
                                <h2 className="snif-m2 snif-l2-pc snif-semibold snif-bold-pc mb-3 mb-md-4">More recent dog guides</h2>
                            </a>
                        )}
                        <div className="d-md-none">
                            {blogPosts?.slice(...mbArt).map((v) => (
                                <BlogArticle key={v.id} data={v} {...{ useStateSSR }} />
                            ))}
                            {blogPosts?.slice(mbArtSimple).map((v) => (
                                <BlogArticleSimple key={v.id} data={v} {...{ useStateSSR }} />
                            ))}
                        </div>
                        <div className="d-none d-md-block">
                            <div className={b('content_desktop_high')}>
                                {blogPosts?.slice(...pcArt).map((v) => (
                                    <BlogArticleDesktopHigh key={v.id} data={v} {...{ useStateSSR }} />
                                ))}
                            </div>
                            <div className={b('content_desktop_low')}>
                                {blogPosts?.slice(pcArtSimple).map((v) => (
                                    <BlogArticleDesktopLow key={v.id} data={v} {...{ useStateSSR }} />
                                ))}
                            </div>
                        </div>
                        <div className="b-top pt-2 mb-4">
                            <SniffPagination {...{ initialPage, onPageChange, hrefBuilder, pageCount }} />
                        </div>
                        <h2 className="snif-m2 snif-l2-pc snif-semibold snif-bold-pc mb-3 mb-md-4">Most popular dog guides</h2>
                        <div className="d-md-none">
                            {blogs_content.map((v, i) => (
                                <BlogArticleSimple key={i} data={v} {...{ useStateSSR }} />
                            ))}
                        </div>
                        <div className={b('popular-desktop')}>
                            {blogs_content.map((v, i) => (
                                <BlogArticleSimpleDesktop key={i} data={v} {...{ useStateSSR }} />
                            ))}
                        </div>
                        <h2 className="snif-m2 snif-l2-pc snif-semibold snif-bold-pc mt-5 mb-md-4 mb-3 pt-1">Top dog guides per area</h2>
                        <BlogCategories categories={blogCategories} />
                    </div>
                </div>
                <div className="desktop-container">
                    <a href="https://www.sniffspot.com/blog/dog-trainers/united-states" className={b('top-trainers')}>
                        <div className={b('top-trainers_content')}>
                            <h4 className="snif-p snif-l2-pc snif-semibold text-dark mb-1 mb-md-0">
                                We are happy to announce the Sniffspot Top Trainers of 2023!
                            </h4>
                            <p className={b('read-more')}>
                                Read more
                                <SvgKeyboardRight />
                            </p>
                        </div>
                        <PictureSet
                            imgs={topTrainersImg}
                            className={b('top-trainers_thumbnail')}
                            width={600}
                            height={isMobile ? 220 : 233}
                            useState={useStateSSR}
                            SSR={!!useStateSSR}
                            alt="Top Trainers of 2022 thumbnail"
                        />
                    </a>
                </div>
                <BlogFooter {...{ isMobile, useStateSSR }} />
                {!useStateSSR && <FooterNew isHome />}
            </div>
        </div>
    );
};

export default Blog;
