import React, { useEffect, useState } from 'react';
import bemCn from 'bem-cn';
import { ImageHelper } from '../../classes/image-helper.class';
import { IMG_RESIZES } from '../../consts/resizer.consts';
import defSpotImg from '../../assets/img/blog/blog-placeholder.png?sizes[]=450,sizes[]=600';
import PictureSet from '../picture-set';
import SvgKeyboardRight from 'src/assets/svg/SvgKeyboardRight.svg';
import './style.scss';

const b = bemCn('blog-article');

const BlogArticle = ({ data, useStateSSR, method = 'lazy' }) => {
    const [myDesc, setMyDesc] = useState('');
    const thumbnail = data.thumbnail ? ImageHelper.getUrlFromOptions(data.thumbnail, `fit-in/${IMG_RESIZES.x0256}`) : defSpotImg;

    useEffect(() => {
        setMyDesc(data.descriptionShort || data.description);
    }, []);

    return (
        <a href={data.slug || data.url} className={b()}>
            <PictureSet
                imgs={thumbnail}
                className={b('thumbnail')}
                width={450}
                height={220}
                useState={useStateSSR}
                SSR={!!useStateSSR}
                alt={data.title + ' thumbnail'}
                method={method}
            />
            <h4 className={b('title')}>{data.title}</h4>
            <div className={b('desc')} dangerouslySetInnerHTML={{ __html: myDesc }} />
            <p className={b('read-more')}>
                Read more
                <SvgKeyboardRight />
            </p>
        </a>
    );
};

export default BlogArticle;
